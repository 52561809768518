import React, { useState,useEffect,useCallback } from 'react';

import { createWeb3Modal, defaultConfig,useWeb3ModalProvider, useSwitchNetwork} from '@web3modal/ethers/react'

import {debounce} from 'lodash';

import {
  ChakraProvider,
  Box,
  VStack,
  HStack,
  Heading,
  Select,
  NumberInput,
  NumberInputField,
  Button,
  Text,
  Flex,
  Spacer,
  Image, 
  useToast
} from '@chakra-ui/react';

import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';

import {
  ethers,
  Contract,
  BrowserProvider,

} from 'ethers'

import fromchainId from './constants/chainIdtoName.json'

import { abi } from './constants/abi';

import contracts from './constants/contract.json'

import RPCURL from './constants/rpcUrls.json'

import { ethereum, base,arbitrum,scroll,optimism,linea,blast } from './constants/chains';

import selectedChain from './constants/selctedchain.json' //chains Json network name to chain ID

import {  queryGraphQL ,startPolling} from '@bvvvp009/hyperstatus';

import { useWeb3ModalState  } from '@web3modal/ethers/react'




// 1. Get projectId

const projectId = process.env.REACT_APP_WALLET_CONNECT

// 2. Set chains

// 3. Create a metadata object
const metadata = {
  name: 'Hyperlane Refuel',
  description: 'Refill Native gas in supported chains',
  url: 'https://hyperfill.fun', // origin must match your domain & subdomain
  icons: ['https://hyperfill.fun']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [ethereum,arbitrum,base,scroll,optimism,linea,blast],
  projectId,
  themeVariables: {
   
    '--w3m-color-mix-strength': 40,
    '--w3m-accent':'#D53F8C',
    
  }
}).getError( (value) => {
  console.log(value); // Success!
},
(reason) => {
  console.error(reason); // Error!
},)


function App() {
  const { open, selectedNetworkId } = useWeb3ModalState();
  const [chain1, setChain1] = useState(!selectedNetworkId?'Select Chain':fromchainId[selectedNetworkId]);
  const [chain2, setChain2] = useState('Select Chain');
  const [gasAmount, setGasAmount] = useState(0);
  const [fee, setFee] = useState(0);
  const { walletProvider } = useWeb3ModalProvider()
  const { switchNetwork } = useSwitchNetwork()
  const [fees_amount,setFeeAmount] = useState()
  const [fee_approx,setFee_approx] = useState()
  const [is_loading,setis_loading] = useState('loading');
  const [balances, setBalances] = useState({
    ethereum: 0,
    arbitrum: 0,
    base: 0,
    scroll: 0,
    optimism: 0,
    linea: 0,
    sepolia: 0,
    scrollsepolia: 0
  });
  const [ethPrice, setEthPrice] = useState(null);

  const toast = useToast({
    position:'top-right',
    
  });


  useEffect(() => {
    if (selectedNetworkId) {
      setChain1(fromchainId[selectedNetworkId]);
    } else {
      setChain1('Select Chain');
    }
  }, [selectedNetworkId]);


  const chains = [
    { name: 'Ethereum', image: './blockchainlogos/ethereum.png' },
    { name: 'Arbitrum', image: './blockchainlogos/arbitrum.png' },
    { name: 'Base', image: './blockchainlogos/base.png' },
    { name: 'Scroll', image: './blockchainlogos/scroll.png' },
    { name: 'OP', image: 'op.png' },
    { name: 'Linea', image: 'linea.png' },
    { name: 'Blast', image: 'blast.png' },

  ];

  const fetchPrice = async () => {
     // Replace with your own RPC endpoint URL
     const provider = new ethers.JsonRpcProvider('https://cloudflare-eth.com');
  
     // Replace with the Chainlink ETH/USD Data Feed contract address
     const chainlinkETHUSDAddress = '0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419';
   
     // ABI to interact with the Chainlink contract (simplified for price fetching)
     const abi_eth = [
       {
         "inputs": [],
         "name": "latestRoundData",
         "outputs": [
           { "internalType": "uint80", "name": "roundId", "type": "uint80" },
           { "internalType": "int256", "name": "answer", "type": "int256" },
           { "internalType": "uint256", "name": "startedAt", "type": "uint256" },
           { "internalType": "uint256", "name": "updatedAt", "type": "uint256" },
           { "internalType": "uint80", "name": "answeredInRound", "type": "uint80" }
         ],
         "stateMutability": "view",
         "type": "function"
       }
     ];
   
     const priceFeed = new ethers.Contract(chainlinkETHUSDAddress, abi_eth, provider);
    try {
      const roundData = await priceFeed.latestRoundData();
      const price = roundData.answer.toString();
      console.log(parseFloat(ethers.formatUnits(price, 8)).toFixed(2))
      setEthPrice(parseFloat(ethers.formatUnits(price, 8)).toFixed(2));
    } catch (error) {
      console.error('Error fetching price:', error);
    }
  };

  useEffect(() => {
    // Fetch price on page load
    fetchPrice();

    // Set interval to fetch price every 3 to 5 minutes (180000 to 300000 ms)
    const interval = setInterval(() => {
      fetchPrice();
    }, 180000); // 3 minutes (you can adjust this to 300000 for 5 minutes)

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);
 
  const swapChains = () => {
    
    if (!chain1 || !chain2) {
      toast({
        title: 'Error',
        description: 'Select a chain',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    switchNetwork(selectedChain[chain2]).then( (value) => {
      console.log(value); // Success!
    }).then((err)=>{
      console.log(err)
    }) //switched chain when switch network is clicked 
    setChain1(chain2);
    setChain2(chain1);
  };

  const fetchFee = async (amount) => {  
   
    try {

      if (!chain1 || !chain2 || !amount) {
        
      if(!chain1) throw Error("Select Source")
        if(!chain2) throw Error("Select Destination")
          if(!amount) throw Error("Select Amount")
      };
console.log(chain1,chain2,amount,RPCURL[chain1],RPCURL[chain2])

    const ethersProvider = new BrowserProvider(walletProvider);
    // const signer = await ethersProvider.getSigner();
    const contract = new Contract("0x7680372BE58FAa2A0cEAAEa56B252095E3ee5F3D", abi, ethersProvider);
    const send_to = await contract.addressToBytes32("0x7680372BE58FAa2A0cEAAEa56B252095E3ee5F3D");

    const fees = await contract.getFee(
      selectedChain[chain2],
      send_to,
      ethers.parseEther(amount.toString())
    );
    console.log(fees,ethers.parseEther(amount.toString()))
    const totalValue = await fees+ethers.parseEther(amount.toString())
    console.log(totalValue)
    setFee(parseFloat(ethers.formatEther(fees)).toFixed(4));
   
   
    setFee_approx(fees?(parseFloat(ethers.formatUnits(totalValue)).toFixed(4)).toString():0)

    

  } catch (error) {
    console.error(error);
    toast({
      title: 'Error',
      description: `Error fetching fee: ${error.reason || error.message}`,
      status: 'error',
      duration: 5000,
      isClosable: true,
      position:'top-right'
      
    });
  }
  };


 

  const sendGas = async () => {
    const id ='bridging-toast'
  
    try {
      
     // Check if the correct network is selected
      if (selectedNetworkId !== selectedChain[chain1]) {
        toast({
          title:'Chains mismatch.',
          description:'Switching chains...',
          status:'warning',
          autoClose:selectedNetworkId===selectedChain[chain1]?false:3000
        
          });
        await switchNetwork(selectedChain[chain1]);
      }
      toast({
        id:id,
        title:'New Bridge Transaction.',
        description:"Accept transaction in wallet.",
        status:"info",

      })
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(contracts[selectedChain[chain1]], abi, signer);
      
      const send_to = await contract.addressToBytes32(contracts[selectedChain[chain2]]);
      const fee = await contract.getFee(selectedChain[chain2], send_to, ethers.parseEther(gasAmount.toString()));
      const totalValue = fee + (ethers.parseEther((gasAmount*1.0099).toString()));
  
      // Check wallet balance
      const feesWallet = await ethersProvider.getBalance(signer.address);
      if (feesWallet < ethers.toBigInt(totalValue)) {
        throw new Error("Not enough balance in wallet. Please refill from other chains.");
      }
  
      // Check contract balance
      const checkBalance = new ethers.JsonRpcProvider(RPCURL[chain2]);
      const contractBalance = await checkBalance.getBalance("0x7680372BE58FAa2A0cEAAEa56B252095E3ee5F3D");
      if (contractBalance < ethers.toBigInt(totalValue)) {
        throw new Error("Contract reserves are dried up. Please wait for refill.");
      }
  
      setFeeAmount((parseFloat(ethers.formatUnits(totalValue)).toFixed(4)).toString());
  
      const tx = await contract.dispatch(selectedChain[chain2].toString(), send_to, ethers.parseEther(gasAmount), {
        value: totalValue
      });

      let receipt = null;

      const receiptPromise = new Promise(async (resolve, reject) => {
        try {
          receipt = await tx.wait();
          console.log(receipt);
          resolve(receipt); // Resolve with the receipt
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
      
      toast.promise(receiptPromise, {
        success: { title: 'Transaction Mined', description: `Transaction confirmed. Hash: ${tx.hash.substring(0, 10)}...` },
        error: { title: 'Transaction Failed', description: `Error Occurred` },
        loading: { title: 'Transaction Status', description: 'Waiting for confirmations..' },
      });
      
      // Use .then() to delay handleSearch until after the toast.promise resolves
      receiptPromise
        .then((resolvedReceipt) => {
          console.log(resolvedReceipt)
          setTimeout(async () => {
            console.log(resolvedReceipt?.hash)
            await handleSearch(resolvedReceipt?.hash);
          }, 8000);
        })
        .catch((error) => {
          // Handle any errors if needed
          console.error('Failed to process the transaction:', error);
        });

    } catch (error) {
      console.error(error);
      setis_loading('error');
      toast.update(id,{description:`Error occurred: ${error.reason}`, id, duration: 3000 });
    }
  };
  
  const handleSearch = async (search) => {
    try {
      const initialResults = await queryGraphQL({search});

      if(initialResults[0]?.status === "Delivered"){
        toast({ 
          render:()=>(
         <Box color='white' p={3} bg= {initialResults[0]?.details?.is_delivered?'green.500':'blue.500'}>
         <Text>Message status: {initialResults[0]?.status} </Text>
         <Text> From: {initialResults[0]?.from} </Text>
         <Text> To: {initialResults[0]?.to} </Text>
          </Box>
          ),
          status:"success",
          autoClose:5000,
          closeOnClick: true,
          draggable: true,
          closeButton: true,

        });
      }
     
      const pendingMessageIds = initialResults.filter(msg => msg.status === 'Pending' && !msg.isNonEVM).map(msg => msg.id);
      
      localStorage.setItem("keys",pendingMessageIds);

      startPolling(pendingMessageIds, 15000, (updatedMessages) => {
        const message = updatedMessages[0];
        if (message) {
          const { status, from, to, details } = message;
          let toastType = 'loading';
          if (status === 'Delivered') {
            toastType = 'success';
          } else if (status === 'Failed') {
            toastType = 'error';
          }
  
          toast({ 
            render:()=>(
              <Box color='white' p={3} bg= {details?.is_delivered?'green.500':'blue.500'}>
            <Text size={"lg"}>Message status: {status} </Text>
            <Text>Id:{details?.msg_id.substring(0,10)}...</Text>
           <Text> From: {from} </Text>
           <Text> To: {to} </Text>
            </Box>
            ),
            status:toastType.toString(),
            autoClose: details?.is_delivered,
            closeOnClick: true,
            draggable: true,
            closeButton: true,
          });
        }
      });
    } catch (error) {
      console.error(error);
      toast({description:'Error checking message status',
        status:'error'
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchFee = useCallback(
    debounce((amount) => {
      fetchFee(amount);
    }, 500), // 500ms debounce time
    [chain1, chain2]
  );

  useEffect(() => {
    if (gasAmount > 0) {
      debouncedFetchFee(gasAmount);
    }
  }, [gasAmount, debouncedFetchFee]);


  useEffect(() => {
    const getBalances = async () => {
      const balances = {};
      for (const chain in RPCURL) {
        try {
          const provider = new ethers.JsonRpcProvider(RPCURL[chain]);
          const balance = await provider.getBalance("0x7680372BE58FAa2A0cEAAEa56B252095E3ee5F3D");
          balances[chain] = ethers.formatEther(balance);
        } catch (error) {
          console.error(`Error fetching balance for ${chain}:`, error);
          balances[chain] = "0";
        }
      }
      setBalances(balances);
    };
    getBalances();
  }, []);
  return (
    <ChakraProvider>
    <Box bg="lightblue" minH="100vh" p={4}>
      <Flex as="header" bg="white" p={4} boxShadow="md">
        <Image src="./logomain.png" alt="Logo" boxSize="50px" />
        <Heading color="pink.500" as={'h3'}>HyperFill</Heading>
        <Spacer />
        <Button colorScheme="pink">
          <w3m-button backgroundColor='pink' balance='show' />
        </Button>
      </Flex>
      <VStack spacing={8} align="center" marginTop={"40px"}>
        <Heading size={'lg'} color="pink.500">ReFill</Heading>
        <Box w="full" maxW="lg" bg="white" p={6} borderRadius="md" boxShadow="md">
          <VStack spacing={6} align="stretch">
            {/* Part 1: Chain Selection */}
            <HStack>
              <Select
                placeholder="Select Chain"
                value={chain1}
                onChange={(e) => {
                  console.log(e.target.value)
                  setChain1(e.target.value)
                }}
              >
                {chains.map((chain) => (
                  <option key={chain.name} value={chain.name} disabled={chain2 === chain.name} style={{ width: '30px', marginRight: '10px', height: '30px' }}>
                    {chain.name}
                  </option>
                ))}
              </Select>
              <Button onClick={swapChains} bg="pink.500" color="white" _hover={{ bg: "pink.600" }}>
                <ArrowLeftIcon />
                <ArrowRightIcon />
              </Button>
              <Select
                placeholder="Select Chain"
                value={chain2}
                onChange={(e) => setChain2(e.target.value)}
              >
                {chains.map((chain) => (
                  <option key={chain.name} value={chain.name} disabled={chain1 === chain.name}>
                    {chain.name}
                  </option>
                ))}
              </Select>
            </HStack>
            {/* Part 2: Gas Amount and Details */}
            <VStack align="stretch">
              <NumberInput
                value={gasAmount}
                onChange={(value) => setGasAmount(value)}
                min={chain1 && chain2 ? 0.0005 : 0}
                max={0.01}
                step={0.0001}
                precision={4}
              >
                <NumberInputField placeholder={chain1 && chain2 ? 0.0005 : "Enter Gas Amount"} />
              </NumberInput>
              <Text>Fee:{fee} ({(ethPrice * fee).toFixed(3)}$)</Text>
              <Text>Total Amount to Bridge: {fee_approx ? fee_approx : 0} ({(ethPrice * fee_approx).toFixed(3)}$) </Text>
            </VStack>
            {/* Part 3: Bridge Button */}
            <Button colorScheme="pink" onClick={sendGas}>
              Bridge
            </Button>
          </VStack>
        </Box>
      </VStack>
      <Box as="footer" mt={8} textAlign="center">
        {/* <Flex w="100%" mt={4} justify="space-between">
          {Object.entries(balances).map(([chain, balance]) => (
            <GasCan key={chain} chain={chain} balance={balance} />
          ))}
        </Flex> */}
        <Button marginTop={'20px'} as="a" href="https://github.com/bvvvp009" target="_blank" rel="noopener noreferrer" colorScheme="pink" variant="link">
          🧑‍💻 Bvvvp009
        </Button>
        <Flex justify="center" align="center" mt={4}>
          <Text fontSize="sm" color="gray.500" mr={2}>Powered by</Text>
          <Image src="./symbol.svg" alt="Symbol" boxSize="20px" mr={1} />
          <Image src="./hyperlane.svg" alt="Hyperlane" boxSize="50px" />
        </Flex>
      </Box>
    </Box>
    <Box position="absolute" bottom={4} right={4}>
    <Button
      as="a"
      href="https://github.com/bvvvp009"
      target="_blank"
      rel="noopener noreferrer"
      colorScheme="pink"
      variant="link"
      leftIcon={<Image src="https://img.icons8.com/?size=100&id=62856&format=png&color=F25081" alt="GitHub" boxSize="16px" />}
      mr={4}
    >
      GitHub
    </Button>
    <Button
      as="a"
      href="https://twitter.com/0xhyperfill"
      target="_blank"
      rel="noopener noreferrer"
      colorScheme="pink"
      variant="link"
      leftIcon={<Image src="https://img.icons8.com/?size=100&id=8824&format=png&color=F25081"  alt="Twitter" boxSize="16px" />}
    >
      Twitter
    </Button>
  </Box>
  </ChakraProvider>
  
  );
}

export default App;
