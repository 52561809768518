const ethereum = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
  }
  
  const scroll = {
    chainId: 534352 ,
    name: 'Scroll',
    currency: 'ETH',
    explorerUrl: 'https://scrollscan.com/',
    rpcUrl: 'https://1rpc.io/scroll'
  }
  
  const arbitrum = {
    chainId:  42161,
    name: 'Arbitrum',
    currency: 'ETH',
    explorerUrl: 'https://arbiscan.io',
    rpcUrl: 'https://arbitrum.llamarpc.com'
  }
  
  const base = {
    chainId:  8453,
    name: 'Base',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://arbitrum.llamarpc.com'
  }

  const optimism = {
    chainId:  10,
    name: 'OP Mainet',
    currency: 'ETH',
    explorerUrl: 'https://optimistic.etherscan.io/',
    rpcUrl: 'https://mainnet.optimism.io'
  }
  
  const linea = {
    chainId:  59144,
    name: 'Linea Mainnet',
    currency: 'ETH',
    explorerUrl: 'https://lineascan.build/',
    rpcUrl: 'https://rpc.linea.build'
  }

  const blast = {
    chainId:  81457,
    name: 'Blast Mainnet',
    currency: 'ETH',
    explorerUrl: 'https://blastexplorer.io/',
    rpcUrl: 'https://rpc.blast.io'
  }

  const scrollsepolia = {
    chainId:534351,
    name: 'Scroll Sepolia',
    currency: 'ETH',
    explorerUrl: 'https://sepolia.scrollscan.dev/',
    rpcUrl: 'https://sepolia-rpc.scroll.io'

  }

  const sepolia = {
    chainId:11155111,
    name: 'Sepolia',
    currency: 'ETH',
    explorerUrl: 'https://sepolia.etherscan.io',
    rpcUrl: 'https://sepolia.infura.io/v3/2HAwvcGoy6rRxpLAPtu0rOLJcD1'

  }
  
  export {sepolia,scrollsepolia,ethereum,scroll,arbitrum,base,optimism,linea,blast}
